export const setOverflowHidden = state => {
  document.body.style.overflow = state ? 'hidden' : ''
  // document.querySelector('html').style.overflow = state ? 'hidden' : ''
}

export const CapitalizeFirstLetter = word => {
  if (typeof word !== 'string') return word
  return `${word.slice(0, 1).toUpperCase()}${word.slice(1, word.length)}`
}

export const stringSplit = (string, splitPosition = 10) => {
  if (typeof string !== 'string' && string.length > 0) return string
  return string.match(new RegExp(`.{1,${splitPosition}}`, 'g'))
}
