module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Suisse Works","SuisseIntl"],"urls":["fonts/fonts.css"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
