/**
 * Remember to import it in gatsby-browser.js
 */

import React, { useState } from 'react'

import { setOverflowHidden } from '../../utils/helpers'
import AppContext from './Context'

export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    showMenu: false,
    isPlaying: false
  })
  return (
    <AppContext.Provider
      value={{
        state: state,
        actions: {
          toggleMenu: condition => {
            const toggledState = !state.showMenu
            setOverflowHidden(condition ? condition : toggledState)
            setState({
              ...state,
              showMenu: condition ? condition : toggledState
            })
          },
          togglePlaying: documentary => {
            const isSame =
              state?.isPlaying && documentary.id === state?.isPlaying.id
            setOverflowHidden(isSame ? false : true)
            setState({
              ...state,
              isPlaying: isSame ? null : documentary
            })
          }
        }
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
